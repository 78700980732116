import { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { firestore, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import './LinkPermissions.css';

// Define permission levels
enum PermissionLevel {
  VIEWER = 'viewer',
  EDITOR = 'editor',
  OWNER = 'owner'
}

// Interface for permission data
interface Permission {
  id: string;
  linkId: string;
  userId: string;
  email: string;
  level: PermissionLevel;
  createdAt: Timestamp;
  createdBy: string;
}

// Interface for invitation data
interface Invitation {
  id: string;
  linkId: string;
  email: string;
  level: PermissionLevel;
  createdAt: Timestamp;
  createdBy: string;
  token: string;
  accepted: boolean;
  expiresAt: Timestamp;
}

interface LinkPermissionsProps {
  shortCode: string;
  onClose: () => void;
}

const LinkPermissions = ({ shortCode, onClose }: LinkPermissionsProps) => {
  const [user] = useAuthState(auth);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newEmail, setNewEmail] = useState('');
  const [newPermissionLevel, setNewPermissionLevel] = useState<PermissionLevel>(PermissionLevel.VIEWER);
  const [isOwner, setIsOwner] = useState(false);
  const [linkDetails, setLinkDetails] = useState<any>(null);
  const [latestInvitation, setLatestInvitation] = useState<{token: string, email: string} | null>(null);

  // Function to fetch link permissions
  const fetchPermissions = async () => {
    try {
      setLoading(true);

      // First, get the link details to check ownership
      const linkDoc = await getDoc(doc(firestore, 'shortenedURLs', shortCode));
      if (!linkDoc.exists()) {
        setError('Link not found');
        setLoading(false);
        return;
      }

      const linkData = linkDoc.data();
      setLinkDetails(linkData);

      // Check if current user is the owner
      const isCurrentUserOwner = linkData.userId === user?.uid;
      setIsOwner(isCurrentUserOwner);

      if (!isCurrentUserOwner) {
        // Check if user has permission through the permissions collection
        const permissionsQuery = query(
          collection(firestore, 'linkPermissions'),
          where('linkId', '==', shortCode),
          where('userId', '==', user?.uid)
        );

        const permissionSnapshot = await getDocs(permissionsQuery);
        if (permissionSnapshot.empty) {
          setError('You do not have permission to view this link\'s permissions');
          setLoading(false);
          return;
        }

        const userPermission = permissionSnapshot.docs[0].data();
        if (userPermission.level !== PermissionLevel.OWNER) {
          setError('You need owner permission to manage this link\'s permissions');
          setLoading(false);
          return;
        }

        setIsOwner(true);
      }

      // Fetch all permissions for this link
      const permissionsQuery = query(
        collection(firestore, 'linkPermissions'),
        where('linkId', '==', shortCode)
      );

      const permissionSnapshot = await getDocs(permissionsQuery);
      const permissionsData = permissionSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Permission[];

      // Add the owner to the permissions list
      const ownerPermission: Permission = {
        id: 'owner',
        linkId: shortCode,
        userId: linkData.userId,
        email: linkData.userEmail || 'Unknown',
        level: PermissionLevel.OWNER,
        createdAt: linkData.createdAt,
        createdBy: linkData.userId
      };

      setPermissions([ownerPermission, ...permissionsData]);

      // Fetch all invitations for this link
      const invitationsQuery = query(
        collection(firestore, 'linkInvitations'),
        where('linkId', '==', shortCode),
        where('accepted', '==', false)
      );

      const invitationSnapshot = await getDocs(invitationsQuery);
      const invitationsData = invitationSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Invitation[];

      setInvitations(invitationsData);

    } catch (err: any) {
      setError(`Error fetching permissions: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Load permissions when component mounts
  useEffect(() => {
    if (user) {
      fetchPermissions();
    }
  }, [shortCode, user]);

  // Function to add a new permission
  const addPermission = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newEmail) {
      setError('Email is required');
      return;
    }

    try {
      setLoading(true);
      setLatestInvitation(null); // Reset latest invitation

      // Call the Cloud Function to add permission
      const addLinkPermission = httpsCallable(functions, 'urlshortener_grantLinkPermission');
      const result = await addLinkPermission({
        linkId: shortCode,
        email: newEmail,
        level: newPermissionLevel
      });

      // Refresh the permissions list
      await fetchPermissions();

      // Check for invitation token in the result
      if (result.data && (result.data as any).invitationToken) {
        setLatestInvitation({
          token: (result.data as any).invitationToken,
          email: newEmail
        });
      }

      // Clear the form
      setNewEmail('');
      setNewPermissionLevel(PermissionLevel.VIEWER);

    } catch (err: any) {
      setError(`Error adding permission: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to remove a permission
  const removePermission = async (permissionId: string) => {
    try {
      setLoading(true);

      // Call the Cloud Function to remove permission
      const removeLinkPermission = httpsCallable(functions, 'urlshortener_revokeLinkPermission');
      await removeLinkPermission({
        linkId: shortCode,
        targetUserId: permissionId
      });

      // Refresh the permissions list
      await fetchPermissions();

    } catch (err: any) {
      setError(`Error removing permission: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to cancel an invitation
  const cancelInvitation = async (invitationId: string) => {
    try {
      setLoading(true);

      // Call the Cloud Function to cancel invitation
      const cancelLinkInvitation = httpsCallable(functions, 'urlshortener_cancelInvitation');
      await cancelLinkInvitation({
        linkId: shortCode,
        invitationId
      });

      // Refresh the invitations list
      await fetchPermissions();

    } catch (err: any) {
      setError(`Error cancelling invitation: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to copy invitation link
  const copyInvitationLink = (token: string) => {
    const invitationLink = `${window.location.origin}/invite/${token}`;
    navigator.clipboard.writeText(invitationLink);
    alert('Invitation link copied to clipboard');
  };

  return (
    <div className="permissions-modal">
      <div className="permissions-content">
        <h2>Manage Access for {linkDetails?.title || shortCode}</h2>
        <button className="close-button" onClick={onClose}>×</button>

        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}

        {isOwner && (
          <div className="add-permission-form">
            <h3>Invite People</h3>
            <form onSubmit={addPermission}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Enter email address"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="permission-level">Permission Level:</label>
                <select
                  id="permission-level"
                  value={newPermissionLevel}
                  onChange={(e) => setNewPermissionLevel(e.target.value as PermissionLevel)}
                >
                  <option value={PermissionLevel.VIEWER}>Viewer (can only view statistics)</option>
                  <option value={PermissionLevel.EDITOR}>Editor (can edit link metadata)</option>
                  <option value={PermissionLevel.OWNER}>Owner (full control)</option>
                </select>
              </div>

              <button type="submit" disabled={loading}>
                Send Invitation
              </button>
            </form>
            
            {/* Display latest invitation link */}
            {latestInvitation && (
              <div className="latest-invitation">
                <h4>Invitation Link for {latestInvitation.email}</h4>
                <div className="invitation-link-container">
                  <input 
                    type="text" 
                    readOnly 
                    value={`${window.location.origin}/invite/${latestInvitation.token}`}
                    className="invitation-link-input"
                  />
                  <button 
                    className="copy-button"
                    onClick={() => copyInvitationLink(latestInvitation.token)}
                  >
                    Copy Link
                  </button>
                </div>
                <p className="invitation-note">
                  Share this link with {latestInvitation.email} to grant them access.
                </p>
              </div>
            )}
          </div>
        )}

        <div className="permissions-list">
          <h3>People with Access</h3>
          {permissions.length > 0 ? (
            <ul>
              {permissions.map((permission) => (
                <li key={permission.id} className="permission-item">
                  <div className="permission-info">
                    <span className="email">{permission.email}</span>
                    <span className="level">
                      {permission.level === PermissionLevel.OWNER ? 'Owner' :
                       permission.level === PermissionLevel.EDITOR ? 'Editor' : 'Viewer'}
                    </span>
                  </div>

                  {isOwner && permission.id !== 'owner' && permission.userId !== user?.uid && (
                    <button
                      className="remove-button"
                      onClick={() => removePermission(permission.id)}
                      disabled={loading}
                    >
                      Remove
                    </button>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No permissions found.</p>
          )}
        </div>

        {isOwner && invitations.length > 0 && (
          <div className="invitations-list">
            <h3>Pending Invitations</h3>
            <ul>
              {invitations.map((invitation) => (
                <li key={invitation.id} className="invitation-item">
                  <div className="invitation-info">
                    <span className="email">{invitation.email}</span>
                    <span className="level">
                      {invitation.level === PermissionLevel.OWNER ? 'Owner' :
                       invitation.level === PermissionLevel.EDITOR ? 'Editor' : 'Viewer'}
                    </span>
                    <span className="expires">
                      Expires: {invitation.expiresAt.toDate().toLocaleDateString()}
                    </span>
                  </div>

                  <div className="invitation-actions">
                    <button
                      className="copy-button"
                      onClick={() => copyInvitationLink(invitation.token)}
                    >
                      Copy Link
                    </button>
                    <button
                      className="cancel-button"
                      onClick={() => cancelInvitation(invitation.id)}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkPermissions;
