// src/App.tsx
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import NotFound from './components/NotFound';
import PrivateRoute from "./components/PrivateRoute.tsx";
import Login from "./components/Login.tsx";
import './styles.css';
import CreateShortURL from "./components/CreateShortURL.tsx";
import ReportDetails from "./components/ReportDetails.tsx";
import InvitationAccept from "./components/InvitationAccept.tsx";

const router = createBrowserRouter([
    {
        path: "d",
        element: <PrivateRoute />,
        children: [
            {
                path: "",
                loader: () => {
                    return { redirect: "dashboard" };
                }
            },
            {
                path: "dashboard",
                element: <Dashboard />,
                index: true
            },
            {
                path: 'create',
                element: <CreateShortURL />, // New route for URL creation
            },
            {
                path: "reports",
                element: <Reports />
            },
            {
                path: "reports/:shortCode",
                element: <ReportDetails />
            }
        ]
    },
    {
        path: "login",
        element: <Login />
    },
    {
        path: "invite/:token",
        element: <InvitationAccept />
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

const App = () => {
    return <RouterProvider router={router} />;
};

export default App;
