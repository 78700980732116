import { useState, useRef, useEffect } from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import './ProfileButton.css';

const ProfileButton = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect happens automatically via auth state change
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="profile-button-container" ref={dropdownRef}>
      <button 
        className="profile-icon-button" 
        onClick={toggleDropdown}
        aria-label="User profile"
      >
        <svg 
          className="profile-icon" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" 
            fill="currentColor"
          />
          <path 
            d="M12 14C7.58172 14 4 17.5817 4 22H20C20 17.5817 16.4183 14 12 14Z" 
            fill="currentColor"
          />
        </svg>
      </button>
      
      {dropdownOpen && (
        <div className="profile-dropdown-menu">
          <div className="profile-dropdown-email">{auth.currentUser?.email}</div>
          <button 
            className="profile-dropdown-item logout-button" 
            onClick={handleLogout}
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
