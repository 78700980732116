import {useEffect, useState} from 'react';
import {doc, onSnapshot} from 'firebase/firestore';
import {firestore} from '../firebase';
import {CopyToClipbaordButton} from "./CopyToClipboard.tsx";
import {updateLinkMetadata, updateLinkTitle} from "./GeneralInfo.data.ts";
import editIcon from '../assets/edit.svg';
import OpenLinkButton from "./OpenLinkButton.tsx";
import {ShortenedURLData} from "../types/ShortenedURL.ts";
import LinkPreviewCard from "./LinkPreviewCard.tsx";
import LinkPermissions from './LinkPermissions.tsx';
import {format} from 'date-fns';
import './GeneralInfo.styles.css';


interface GeneralInfoProps {
    shortCode: string;
    timeframeVisits?: number;
    botVisits?: number;
    dateRange?: { start: Date; end: Date };
}


const GeneralInfo = ({shortCode, timeframeVisits, botVisits, dateRange}: GeneralInfoProps) => {
    const [generalInfo, setGeneralInfo] = useState<ShortenedURLData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showPermissions, setShowPermissions] = useState(false);

    useEffect(() => {
        setLoading(true);

        return onSnapshot(doc(firestore, 'shortenedURLs', shortCode), (docSnap) => {
            if (docSnap.exists()) {
                setGeneralInfo(docSnap.data() as ShortenedURLData);
            } else {
                setError('No data found for this shortened URL.');
            }
            setLoading(false);
        }, (err) => {
            setError(`Failed to fetch general information. ${err.message}`);
        });
    }, [shortCode]);

    const handleOpenPermissions = () => {
        setShowPermissions(true);
    };

    const handleClosePermissions = () => {
        setShowPermissions(false);
    };

    if (loading) return <p>Loading general info...</p>;
    if (error) return <p style={{color: 'red'}}>{error}</p>;

    return (
        generalInfo && (
            <div className="general-info-container">
                <div className="info-left">
                    <h3>General Information</h3>
                    <LinkTitle title={generalInfo.title} shortCode={generalInfo.shortCode}/>
                    <div className={'inline-flex'}>
                        <p className={'ellipsis'}><strong>Original URL:</strong> {generalInfo.originalURL}</p>
                        <OpenLinkButton link={generalInfo.originalURL}/>
                    </div>
                    <div className="inline-flex"><p><strong>Shortened URL:</strong> /{generalInfo.shortCode} </p>
                        <CopyToClipbaordButton text={`${window.location.origin}/${generalInfo.shortCode}`}/></div>
                    
                    {/* Visit information */}
                    <p><strong>Total Visits:</strong> {generalInfo.visits}</p>
                    
                    {dateRange && timeframeVisits !== undefined && (
                        <div className="timeframe-visits">
                            <p>
                                <strong>Visits in selected timeframe:</strong> {timeframeVisits - (botVisits || 0)}
                                {timeframeVisits > 0 && (
                                    <span className="timeframe-date">
                                        ({format(dateRange.start, 'MMM d, yyyy')} - {format(dateRange.end, 'MMM d, yyyy')})
                                    </span>
                                )}
                            </p>
                            {botVisits !== undefined && botVisits > 0 && (
                                <p className="bot-visits">
                                    <strong>Bot visits in timeframe:</strong> {botVisits}
                                </p>
                            )}
                        </div>
                    )}
                    
                    <p><strong>Created At:</strong> {new Date(generalInfo.createdAt.seconds * 1000).toLocaleString()}
                    </p>
                    <button 
                        className="manage-access-button" 
                        onClick={handleOpenPermissions}
                    >
                        Manage Access
                    </button>
                </div>
                <div className="info-right">
                    {generalInfo.metadata ? <LinkPreviewCard metadata={generalInfo.metadata} shortUrl={shortCode}/> : (
                        <button onClick={() => updateLinkMetadata(shortCode)}>Fetch Metadata</button>
                    )}
                </div>
                
                {showPermissions && (
                    <LinkPermissions 
                        shortCode={shortCode} 
                        onClose={handleClosePermissions} 
                    />
                )}
            </div>
        )
    );
};

const LinkTitle = ({title, shortCode}: { title?: string, shortCode: string }) => {

    function showPrompt() {
        const newTitle = prompt('Please enter a title', title);

        if (newTitle) {
            updateLinkTitle(shortCode, newTitle).catch((err) => {
                alert(`Error ${err.message}`)
            });
        }
    }

    return !title ? <p>
            <button onClick={showPrompt}>Set title</button>
        </p>
        : <p className={'inline-flex'}><strong>Title:</strong> {title}
            <button className="icon-button" title={'Edit title'} onClick={showPrompt}><img src={editIcon}
                                                                                           alt={'edit icon'}/></button>
        </p>
}

export default GeneralInfo;
