import { useNavigate } from 'react-router-dom';
import { CopyToClipbaordButton } from "./CopyToClipboard.tsx";
import OpenLinkButton from "./OpenLinkButton.tsx";

interface LinkItemProps {
    title?: string;
    originalURL: string;
    shortCode: string;
    createdAt: any; // Firestore timestamp
    visits?: number;
    isShared?: boolean;
}

const LinkItem = ({originalURL, shortCode, createdAt, visits, title, isShared}: LinkItemProps) => {
    const navigate = useNavigate();

    // Navigate to detailed reports
    const handleViewReport = () => {
        navigate(`/d/reports/${shortCode}`, { replace: false });
    };

    return (
        <div className={`link-card ${isShared ? 'shared-link' : ''}`}>
            {isShared && (
                <div className="shared-badge">
                    <span>Shared with you</span>
                </div>
            )}
            {title ? <p><strong>Title:</strong> {title}</p> : null}
            <div className={'inline-flex'}><p className="text-ellipsis"><strong>Original URL:</strong> {originalURL} </p>
                <OpenLinkButton link={originalURL}/></div>
            <div className="inline-flex"><p><strong>Short Code:</strong> {shortCode}</p><CopyToClipbaordButton
                text={`${window.location.origin}/${shortCode}`}/></div>
            <p><strong>Visits:</strong> {visits || 0} visits</p> {/* Display number of visits */}
            <p><strong>Created At:</strong> {new Date(createdAt.seconds * 1000).toLocaleString()}</p>

            <div className="link-actions">
                {/* Button to view detailed reports */}
                <button onClick={handleViewReport} style={{marginTop: '10px'}}>
                    View Detailed Report
                </button>
                
            </div>
        </div>
    );
};

export default LinkItem;
