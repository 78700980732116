import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const InvitationAccept = () => {
  const { token } = useParams<{ token: string }>();
  const [user, loading] = useAuthState(auth);
  const [accepting, setAccepting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // If no token is provided, redirect to dashboard
    if (!token) {
      navigate('/d/dashboard');
    }
  }, [token, navigate]);

  const acceptInvitation = async () => {
    if (!user || !token) return;

    try {
      setAccepting(true);
      setError(null);

      // Call the Cloud Function to accept the invitation
      const acceptInvitationFn = httpsCallable(functions, 'urlshortener_acceptInvitation');
      await acceptInvitationFn({ token });

      setSuccess(true);
      
      // Redirect to dashboard after 2 seconds
      setTimeout(() => {
        navigate('/d/dashboard');
      }, 2000);
    } catch (err: any) {
      setError(`Error accepting invitation: ${err.message}`);
      console.error(err);
    } finally {
      setAccepting(false);
    }
  };

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (!user) {
    return (
      <div className="container">
        <h2>Please log in to accept this invitation</h2>
        <p>You need to be logged in to accept this invitation.</p>
        <button onClick={() => navigate('/login')}>
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <div className="container">
      <h2>Accept Invitation</h2>
      
      {error && <p className="error">{error}</p>}
      
      {success ? (
        <div className="success-message">
          <p>Invitation accepted successfully!</p>
          <p>Redirecting to dashboard...</p>
        </div>
      ) : (
        <div className="invitation-accept">
          <p>You've been invited to access a shortened URL.</p>
          <p>Click the button below to accept the invitation.</p>
          
          <button 
            onClick={acceptInvitation} 
            disabled={accepting}
            className="accept-button"
          >
            {accepting ? 'Accepting...' : 'Accept Invitation'}
          </button>
        </div>
      )}
    </div>
  );
};

export default InvitationAccept;
