import { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase'; // Firebase Auth and Firestore
import {
    collection,
    DocumentData,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where,
    doc,
    getDoc
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import useUserRole from './useUserRole';
import LinkItem from "./LinkItem.tsx";
import ProfileButton from "./ProfileButton.tsx";
import { useDebugMode } from "../utils/useDebugMode.ts"; // Custom hook to fetch user role

interface LinkData {
    id: string;
    originalURL: string;
    shortCode: string;
    createdAt: any; // Firestore timestamp
    lastUpdated: any; // Firestore timestamp
    userId?: string; // Optional userId
    visits?: number;
    title?: string;
    isOwner?: boolean;
    isShared?: boolean;
}

const Dashboard = () => {
    const [links, setLinks] = useState<LinkData[]>([]);
    const isDebugMode = useDebugMode();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const navigate = useNavigate();
    const { role, loading: roleLoading, error: _roleError } = useUserRole(); // Hook to fetch user role

    const pageSize = 10; // Number of documents per page

    // Fetch the user's links from Firestore
    useEffect(() => {
        const fetchLinks = async () => {
            if (!auth.currentUser) return;
            
            setLoading(true);
            setError(null);
            
            try {
                const userId = auth.currentUser.uid;
                const shortenedURLsRef = collection(firestore, 'shortenedURLs');
                let linksData: LinkData[] = [];
                
                // For admins, fetch all links
                if (isDebugMode && role === 'admin') {
                    const q = query(
                        shortenedURLsRef, 
                        orderBy('lastUpdated', 'desc'), 
                        limit(pageSize)
                    );
                    
                    const querySnapshot = await getDocs(q);
                    linksData = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as LinkData[];
                    
                    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    setHasMore(querySnapshot.docs.length === pageSize);
                } 
                // For regular users, fetch their own links and links shared with them
                else {
                    // 1. Fetch user's own links
                    const ownLinksQuery = query(
                        shortenedURLsRef,
                        where('userId', '==', userId),
                        orderBy('lastUpdated', 'desc'),
                        limit(pageSize)
                    );
                    
                    const ownLinksSnapshot = await getDocs(ownLinksQuery);
                    const ownLinks = ownLinksSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        isOwner: true
                    })) as LinkData[];
                    
                    // 2. Fetch links shared with the user through permissions
                    const permissionsRef = collection(firestore, 'linkPermissions');
                    const permissionsQuery = query(
                        permissionsRef,
                        where('userId', '==', userId)
                    );
                    
                    const permissionsSnapshot = await getDocs(permissionsQuery);
                    
                    // Get all the link IDs that the user has access to
                    const sharedLinkIds = permissionsSnapshot.docs.map(doc => doc.data().linkId);
                    
                    // Fetch details for each shared link
                    const sharedLinks: LinkData[] = [];
                    
                    for (const linkId of sharedLinkIds) {
                        const linkDoc = await getDoc(doc(firestore, 'shortenedURLs', linkId));
                        if (linkDoc.exists()) {
                            sharedLinks.push({
                                id: linkDoc.id,
                                ...linkDoc.data(),
                                isShared: true
                            } as LinkData);
                        }
                    }
                    
                    // Combine own links and shared links, sort by lastUpdated
                    linksData = [...ownLinks, ...sharedLinks].sort((a, b) => {
                        return b.lastUpdated.seconds - a.lastUpdated.seconds;
                    });
                    
                    // Limit to pageSize
                    linksData = linksData.slice(0, pageSize);
                    
                    // Set lastVisible to the last item for pagination
                    if (linksData.length > 0) {
                        const lastItem = linksData[linksData.length - 1];
                        // Find the actual document for lastVisible
                        const lastItemDoc = await getDoc(doc(firestore, 'shortenedURLs', lastItem.id));
                        setLastVisible(lastItemDoc);
                    }
                    
                    setHasMore(linksData.length === pageSize);
                }
                
                setLinks(linksData);
            } catch (err) {
                console.error('Error fetching links:', err);
                setError('Failed to fetch links');
            } finally {
                setLoading(false);
            }
        };
        
        fetchLinks();
    }, [role, roleLoading, isDebugMode]);

    // Handle fetching the next page
    const fetchNextPage = async () => {
        if (!lastVisible || !hasMore || !auth.currentUser) return;

        setLoading(true);
        try {
            const userId = auth.currentUser.uid;
            const shortenedURLsRef = collection(firestore, 'shortenedURLs');
            let newLinksData: LinkData[] = [];

            if (isDebugMode && role === 'admin') {
                // Admins fetch the next page of all links
                const q = query(
                    shortenedURLsRef, 
                    orderBy('lastUpdated', 'desc'), 
                    startAfter(lastVisible), 
                    limit(pageSize)
                );
                
                const querySnapshot = await getDocs(q);
                newLinksData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as LinkData[];
                
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setHasMore(querySnapshot.docs.length === pageSize);
            } else {
                // For regular users, pagination is more complex with combined results
                // We'll use the lastUpdated timestamp of the last visible item
                const lastTimestamp = lastVisible.data().lastUpdated;
                
                // 1. Fetch next page of user's own links
                const ownLinksQuery = query(
                    shortenedURLsRef,
                    where('userId', '==', userId),
                    orderBy('lastUpdated', 'desc'),
                    startAfter(lastTimestamp),
                    limit(pageSize)
                );
                
                const ownLinksSnapshot = await getDocs(ownLinksQuery);
                const nextOwnLinks = ownLinksSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    isOwner: true
                })) as LinkData[];
                
                // 2. Fetch links shared with the user through permissions
                // (We need to fetch all and filter by timestamp client-side)
                const permissionsRef = collection(firestore, 'linkPermissions');
                const permissionsQuery = query(
                    permissionsRef,
                    where('userId', '==', userId)
                );
                
                const permissionsSnapshot = await getDocs(permissionsQuery);
                const sharedLinkIds = permissionsSnapshot.docs.map(doc => doc.data().linkId);
                
                const nextSharedLinks: LinkData[] = [];
                
                for (const linkId of sharedLinkIds) {
                    const linkDoc = await getDoc(doc(firestore, 'shortenedURLs', linkId));
                    if (linkDoc.exists()) {
                        const linkData = linkDoc.data();
                        // Only include links that are older than the last visible item
                        if (linkData.lastUpdated && linkData.lastUpdated.seconds < lastTimestamp.seconds) {
                            nextSharedLinks.push({
                                id: linkDoc.id,
                                ...linkData,
                                isShared: true
                            } as LinkData);
                        }
                    }
                }
                
                // Combine and sort next page of links
                newLinksData = [...nextOwnLinks, ...nextSharedLinks].sort((a, b) => {
                    return b.lastUpdated.seconds - a.lastUpdated.seconds;
                });
                
                // Limit to pageSize
                newLinksData = newLinksData.slice(0, pageSize);
                
                // Set lastVisible to the last item
                if (newLinksData.length > 0) {
                    const lastItem = newLinksData[newLinksData.length - 1];
                    const lastItemDoc = await getDoc(doc(firestore, 'shortenedURLs', lastItem.id));
                    setLastVisible(lastItemDoc);
                }
                
                setHasMore(newLinksData.length === pageSize);
            }

            setLinks((prevLinks) => [...prevLinks, ...newLinksData]);
        } catch (err) {
            setError('Failed to fetch links');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="dashboard-header">
                <h1>Your Dashboard</h1>
                <ProfileButton />
            </div>

            <button onClick={() => navigate('/d/create')} style={{ marginTop: '20px' }}>
                Create New Short URL
            </button>

            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="link-grid">
                {links.length > 0 ? (
                    links.map((link) => (
                        <LinkItem
                            key={link.id}
                            originalURL={link.originalURL}
                            shortCode={link.shortCode}
                            createdAt={link.createdAt}
                            visits={link.visits || 0}
                            title={link.title}
                            isShared={link.isShared}
                        />
                    ))
                ) : (
                    <p>No URLs found.</p>
                )}
            </div>

            {/* Pagination Button */}
            {hasMore && !loading && (
                <button onClick={fetchNextPage} style={{ marginTop: '20px' }}>
                    Load More
                </button>
            )}
        </div>
    );
};

export default Dashboard;
