import { GoogleAuthProvider, signInWithPopup, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { auth, TENANT_ID } from '../firebase'; // Import Firebase Auth and tenant ID
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Login = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Set up auth persistence and tenant ID
    useEffect(() => {
        const setupAuth = async () => {
            try {
                // Set persistence to local (survive browser restart)
                await setPersistence(auth, browserLocalPersistence);
            } catch (err: any) {
                console.error('Error setting auth persistence:', err);
            }
        };
        
        setupAuth();
    }, []);

    // Handle Google Sign-In
    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            // Add custom parameter for tenant ID
            provider.setCustomParameters({
                tenant: TENANT_ID
            });
            
            await signInWithPopup(auth, provider);
            navigate('/d/dashboard', { replace: true }); // Use replace: true for React Router v7
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <div className="container">
            <h2>Login</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <p style={{ textAlign: 'center', margin: '20px 0' }}>Sign in with Google</p>
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
    );
};

export default Login;
